@import 'styles/commonStyling';

.actionItem {
    color: $grayscale400;
    font-size: 20px;
    transition: color 0.3s ease-in-out;

    &[data-has-error='true'] {
        color: $errorMiddle !important;
    }
}

.colorPicker {
    padding-top: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 40px;
    border: 1px solid $grayscale300;
}

.color {
    border-radius: 40px;
    width: 15px;
    height: 15px;
}   

.deleteIcon {
    font-size: 20px;
}

.button {
    transition: color 0.3s ease-in-out;
    &:disabled span {
        color: $grayscale300 !important;
        cursor: default;
    }
}