@import '../../styles/commonStyling';

.tableWrap {
  & > div {
    overflow-x: visible;
  }
  table {
    position: relative;
    tr {
      border-bottom: 1px solid $grayscale200;
      th {
        @extend .fontPrimaryNormal;
        font-weight: 500;
        font-size: 16px;
        background: $grayscale50;
        line-height: 140%;
        color: $fontsPrimary;
        user-select: none;
        border: 0;
        padding: 8px;
        text-align: center;
        &[data-disabled='true'] {
          color: $grayscale700;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          opacity: 0.5;
          cursor: initial;
        }
        .thWrapper {
          display: flex;
          align-items: center;
        }
        .iconWrapper {
          margin-left: 8px;
          position: relative;
          height: 9px;
        }
        .icon {
          font-size: 16px;
          position: absolute;
          top: 0;
          transform: translateY(-50%) rotate(180deg);
          vertical-align: middle;
        }
        .activeIcon {
          top: 100%;
          transform: translateY(-50%) rotate(0deg);
        }
      }
      td {
        @extend .fontPrimaryNormal;
        padding: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        border-bottom: none;
        color: $fontsPrimary;
      }
    }
    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .paginationWrap {
    @extend .lytFlexCenter;
    margin: 25px 0;
  }
  .paginationNumbers {
    ul {
      li {
        button {
          @extend .fontPrimaryNormal;
          margin: 0;
          padding: 15px;
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          min-width: auto;
          height: auto;
          color: $fontsPrimary;
          background: transparent;
          &:hover {
            color: $primary50;
          }
          &[aria-current='true'] {
            color: $primary;
            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
  .hide {
    display: none;
  }
  .spinner {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    td {
      border: 0;
    }
  }
}
