@import 'styles/commonStyling';

.actionButton {
    user-select: none;
    display: flex;
    color: $primary;
    padding-inline: 0px !important;
    transition: color 0.3s ease-in-out;

    & > span {
        align-self: center;
    }

    &:disabled {
        color: $grayscale400;
        cursor: initial;
    }
}

.todoInput {
    &::placeholder {
        color: $grayscale400;
    }
    min-width: 0px;
    border: none;
    @extend .fontPrimaryNormal;
    font-weight: 500;
    color: $grayscale600;
    font-size: 16px;
    outline: none;
}

.filterSelected {
    fieldset {
      border: 1px solid $primary;
      background: $primary5;
    }
}