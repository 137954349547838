@import 'styles/commonStyling.scss';

.authorName {
  color: $grayscale500;
  font-size: 12px;
  font-weight: 400;
}

.date {
  color: $grayscale500;
  font-size: 12px;
  font-weight: 500;
}

.icon {
  color: $primary125;
  cursor: pointer;

  &[aria-disabled='true'] {
    color: $grayscale400;
    cursor: not-allowed;
  }
}

.addendumTitle {
  margin-bottom: 4px;
  color: $grayscale700;
  font-size: 16px;
  font-weight: 700;
}
.addendumContent {
  white-space: pre-line;
}
