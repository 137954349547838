@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.fieldError {
  @extend .fontPrimaryNormal;
  margin-top: 3px;
  color: $errorMiddle;
  font-size: 12px;
}

.container {
  height: 15px;
}
