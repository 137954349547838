@import 'src/styles/commonStyling';
@import 'src/styles/responsive';
.pinToTop {
  @extend .pinToTop;
}
.headerContainer {
  background: $grayscale0;
  width: 100%;
  border-bottom: 1px solid $grayscale200;
  .header {
    margin: auto;
    max-width: $screen-large;
    width: 100%;
    height: 64px;
    padding: 0 16px;
    justify-content: flex-start;
    .linksHeaderRow {
      @extend .lytFlexBetween;
      flex-direction: row;
      width: 100%;
      .link {
        color: $grayscale500;
        font-size: 16px;
        margin-left: 6px;
        &:first-child {
          margin-left: 0;
        }
        @extend .lytFlexCenter;
        .menuIcon {
          color: inherit;
          margin-right: 4px;
          font-weight: 500;
        }
      }
      .linkActive {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
    @include media('<large') {
      padding: 16px;
      background: $grayscale0;
      height: auto;
    }
    @extend .lytFlexBetween;
    .logo {
      margin-right: 16px;
      @include media('<large') {
        display: none;
      }
    }
    .wrap {
      @extend .lytFlexBetween;
      margin-left: auto;
      .title {
        display: flex;
        align-items: flex-start;
        @include media('<large') {
          flex-direction: row-reverse;
        }
      }
    }
    .info {
      position: relative;
      padding: 8px 0;
      color: $grayscale500;
      cursor: pointer;
      margin-left: auto;
      @include media('<large') {
        padding: 0;
        color: $fontsPrimary;
      }
      .name {
        font-weight: 700;
        font-size: 18px;
        text-transform: capitalize;
        @include media('<large') {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.24px;
        }
      }
      .status {
        @extend .fontPrimaryNormalText;
        text-align: right;
        text-transform: capitalize;
      }
      .avatar {
        background: $grayscale100;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        span {
          color: $grayscale400;
          font-size: 18px;
        }
      }
      .icon {
        transition: transform 0.2s;
        path {
          fill: $grayscale500;
          @include media('<large') {
            fill: $primary;
          }
        }
      }
      .activeIcon {
        transform: rotate(180deg);
      }
    }
    .logout {
      margin-right: 20px;
      background: #f9f9f9;
      border: 1px solid #d3d3d4;
      border-radius: 80px;
      padding: 4px 27px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .logoutIcon {
      margin-right: 10px;
      color: $grayscale400;
    }
  }
}
