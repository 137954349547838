@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.note {
  @extend .gradientWrapper;
  border-radius: 16px;
  background: radial-gradient(
    227.07% 124.31% at 4.7% 7.78%,
    rgba(255, 175, 187, 0.05) 0%,
    rgba(255, 175, 187, 0.05) 20%,
    rgba(58, 160, 182, 0.05) 40%,
    rgba(87, 202, 225, 0.05) 60%,
    rgba(24, 138, 161, 0.05) 70.31%,
    rgba(115, 102, 216, 0.05) 81.77%,
    rgba(225, 194, 255, 0.05) 100%
  );
  box-shadow: 2px 2px 1px 0px rgba(255, 255, 255, 0.74) inset;

  padding: 8px 12px;
  margin-bottom: 12px;
  cursor: pointer;
  height: 60px;
}
.noteRow {
  @extend .lytFlexBetween;
  margin-bottom: 8px;
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  }
  span {
    font-size: 14px;
    font-weight: 500;
  }
  .date {
    color: $grayscale400;
  }
}
.avatarRow {
  display: flex;
  align-items: center;
  .avatar {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  .avatarName {
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    color: $grayscale500;
  }
  .edit {
    margin-left: 8px;
    font-size: 16px;
    color: $grayscale400;
    cursor: pointer;
  }
}

.draft {
  color: $alert100;
}
