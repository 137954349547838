@import 'styles/commonStyling.scss';

.info {
    @extend .fontPrimaryNormal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $grayscale700;
    max-width: 280px;

    .separator {
        color: $grayscale300;
    }

    &[data-overdue='true'] {
        color: $errorMiddle;
    }

    &[data-status='COMPLETED'] {
        color: $primary125;
    }

    &[data-status='ABANDONED'] {
        color: $grayscale400;
    }
}