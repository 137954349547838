@import 'styles/commonStyling';

.optionsWrapper {
    width: 244px;
    background-color: white;
    box-shadow: 0px 2px 16px 0px #5075B11A;
}

.option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @extend .fontPrimaryNormal;
    padding: 20px 12px;
    color: $grayscale700;
    font-size: 16px;
    font-weight: 500;

    &[data-focus] {
        background-color: $grayscale100;
    }

    &[data-selected] {
        background-color: $primary5;
        color: $primary125;
    }
}

.input {
    outline: none;
    padding: 12px 12px;
    border: 1px solid $grayscale300;
    border-radius: 4px;
    font-size: 16px;
    color: $grayscale500;
    width: 220px;

    &::placeholder {
        color: $grayscale400;
        font-size: 16px;
        font-weight: 500;
    }

    &:disabled {
        background-color: $grayscale100;
    }
}

.closeIcon {
    font-size: 20px;
    color: $primary75;
    cursor: pointer;
}