@import '../../styles/commonStyling';

.accordionHeader {
  height: 40px;
  padding: 16px;
  background: $grayscale50;
  gap: 24px;
  line-height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .title {
    flex: 1;
    h2{

      line-height: 34.1px;
    }
  }
  .arrowBtn {
    border-color: $grayscale200;
    background: transparent;
    span {
      color: $grayscale700;
    }
  }
  .collapse {
    display: flex;
    gap: 14px;
    justify-content: flex-end;
    align-items: center;
  
    .icon {
      width: 20px;
      height: 20px;
      background: $secondary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: white;
        font-size: 14px;
      }
    }
    button {
      width: 40px;
      height: 40px;
      border: 1px solid $grayscale200 span {
        // color: $grayscale700;
      }
    }
  }
}
