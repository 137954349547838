@import 'src/styles/commonStyling';

.avatar {
    position: relative;
    svg {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
.quoteText {
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    color: $grayscale500;
}
