@import 'src/styles/commonStyling.scss';

.textAreaWrapper {
  @extend .gradientWrapper;

  & > textarea {
    @extend .fontPrimaryNormalText;
  }
}
.disabled {
  background: $grayscale100;
}

.microphoneButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid $grayscale200;
  border-radius: 800px;
  background: $grayscale100;

  & > span {
    pointer-events: none;
    color: $grayscale400;
  }
}

.tooltip {
  & > .MuiTooltip-tooltip {
    background: $grayscale700;
  }
}
