@import './include';

$screen-small: 360px;
$screen-medium: 600px;
$screen-large: 1024px;
$screen-xlarge: 1270px;
$screen-xxlarge: 1440px;

$breakpoints: (
  small: $screen-small,
  medium: $screen-medium,
  large: $screen-large,
  xlarge: $screen-xlarge,
  xxlarge: $screen-xxlarge
);
