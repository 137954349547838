@import 'src/styles/responsive';

.layoutContainer {
  padding: 0 16px 0 16px;
  margin: auto;
  display: flex;
  max-width: $screen-large;
  overflow: hidden;

  // @include media('<large') {
  //   margin: 4px 16px 0 16px;
  // }
}

.layoutCondensed {
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: auto;
}
