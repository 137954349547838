@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.goalTracker {
  @extend .lytFlexRow;
  @extend .lytFlexCenter;
  align-items: center;

  .dates {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100px;

    top: -11px;
    position: relative;
    color: $grayscale400;
    @extend .fontPrimaryNormalText;
  }
  .edit {
    top: -11px;
    position: relative;
    width: 28px;
    height: 28px;
    margin-left: 16px;
    button {
      height: unset;
      span {
        font-size: 16px;
        padding: 2px;
        color: $grayscale700;
      }
    }
  }
  .progress {
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 100px;
    align-items: flex-start;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 40px;
    width: 100%;
    margin-left: 16px;
    margin-right: 32px;
  }

  .dashedLine {
    border-bottom: dashed 2px $grayscale200;
    width: 100%;
    position: relative;
    top: 8px;
  }

  @keyframes load {
    0% {
      width: 0;
    }
    100% {
      // width: 68%;
    }
  }
}
.textWithIcon {
  @extend .lytFlexRow;
  @extend .lytFlexCenter;
  @extend .fontPrimaryBoldText;
  color: $grayscale500;
  span {
    font-size: 17px;
  }
}

.hiking {
  color: $blue;
}
.landscape {
  color: $green;
}
.flag {
  color: $purple;
}
