@import 'styles/commonStyling';

.title {
    @extend .fontPrimaryNormal;
    color: $grayscale600;
    font-size: 20px;
    font-weight: 700;
    min-width: 0px;
}

.close {
    display: flex;
    color: $grayscale500;
    font-size: 30px;
    padding-block: 0px !important;
    padding-inline: 0px !important;
    user-select: none;
    & > span {
        align-self: center;
    }
}

.roadmapToggle {
    display: flex;
    padding: 4px;
    border-radius: 30px;
    background: $grayscale200;
    .toggleItem {
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 30px;
        padding: 2px 8px;
        text-align: center;
        vertical-align: middle;
        span {
        font-size: 20px;
        color: $grayscale400;
        }
    }
    .activeToggle {
        background: $primary;
        span {
        color: $grayscale0;
        }
        svg {
        * {
            fill: $grayscale0;
        }
        }
    }
}