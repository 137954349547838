@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.inputContainer {
  display: flex;
}

$checkBoxContainerWidth: 56px; //46
$checkBoxContainerHeight: 32px; //26
$differenceWidth: 10px;
$differenceHeight: 6px;

$checkboxBackgroundColor: $primary;
$toggleBackgroundColor: $grayscale400;

.switch {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
}
.switch i {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  width: $checkBoxContainerWidth;
  height: $checkBoxContainerHeight;
  background-color: $toggleBackgroundColor;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}
.switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: $checkBoxContainerWidth;
  height: $checkBoxContainerHeight;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}
.switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: calc(22px + $differenceHeight);
  height: calc(22px + $differenceHeight);
  background-color: #fff;
  border-radius: calc(11px + $differenceWidth);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}
.switch:active i::after {
  width: calc(22px + $differenceHeight);
  transform: translate3d(2px, 2px, 0);
}
.switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}
.switch input {
  display: none;
}
.switch input:checked + i {
  background-color: $primary;
}
.switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.switch input:checked + i::after {
  transform: translate3d(26px, 2px, 0);
}

.checkBoxLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: $fontsPrimary
}

.disabled {
  .switch {
    cursor: default;
    i {
      background: $grayscale200;
    }
  }
}
.inputContainerSmall {
  .switch i {
    width: 40px;
    height: 24px;
    &:after {
      width: 20px;
      height: 20px;

    }
   &:before {
     width: 40px;
     height: 24px;
   }
  }
  .switch input:checked + i::after {
    transform: translate3d(18px, 2px, 0);
  }
}