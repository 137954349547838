/* stylelint-disable css-modules/no-global-scoped-selector */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.rbc-event {
  padding: unset !important;
  color: unset !important;
}

.rbc-slot-selection {
  background: $primary !important;
  opacity: 0.5;
}

.rbc-event,
.rbc-header + .rbc-header {
  border-left: none !important;
}
.rbc-show-more {
  color: $grayscale600 !important;
  margin-top: 4px !important;
  margin-left: 4px !important;
  font-weight: normal !important;
}
// CALENDAR CUSTOMIZATIONS
//
$calendarBorderColor: $grayscale100;
//
.rbc-day-slot .rbc-events-container {
  margin-right: unset;
}
.rbc-month-view {
  border: 1px solid $calendarBorderColor !important;
  overflow: scroll;
}
.rbc-month-row + .rbc-month-row {
  border: none;
  border-top: 1px solid $calendarBorderColor;
}
.rbc-month-row {
  flex-basis: 80px !important;
}
.rbc-today {
  background-color: $primary5 !important;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid $calendarBorderColor !important;
}
.rbc-month-header {
  background: $grayscale50;
}
.rbc-event-label {
  display: none !important;
}
//DATE OFF  RANGE
.rbc-off-range-bg {
  background: white !important;
  z-index: 10;
  position: relative;
  opacity: 0.3;
}

//CURRENT TIME
.rbc-current-time-indicator {
  background: transparent !important;
  height: 34px !important;
  border: 2px solid #5ebbce !important;
}

.rbc-day-slot .rbc-event {
  background: $primary;
  padding: 5px !important;
}

.rbc-timeslot-group {
  border-bottom: unset !important;
}
// SHOW MORE ON MONTH VIEW DAY
.rbc-show-more {
  font-size: 12px !important;
  margin-top: 0px !important;
}
.rbc-header {
  // background: yellow !important;
  border-bottom: unset !important;
}
.rbc-time-view .rbc-allday-cell {
  display: none !important;
}
.rbc-time-header-cell-single-day {
  display: flex !important;
}
.rbc-time-header-cell-single-day {
  background: #d5eef4;
}
.rbc-header.rbc-today {
  background: #d5eef4 !important;
}
.rbc-today {
  .rbc-time-slot {
    background: #eff9fb;
  }
}

.rbc-event:focus {
  outline: none !important;
}

.rbc-row-segment {
  margin-bottom: 3px;
}

.rbc-time-slot {
  min-height: 25px;
}
