@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.inputContainer {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;

  &[data-disabled='true'] {
    background-color: $grayscale100;
    pointer-events: none;
  }

  .textAreaCounter {
    position: absolute;
    bottom: 12px;
    right: 8px;
    font-size: 12px;
    color: $grayscale400;

    &[data-error='true'] {
      color: $errorMiddle;
    }
  }
  textarea {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 12px;
    padding-bottom: 18px;
    @extend .borderDefault;
    @extend .fontPrimaryNormal;
    font-size: 16px;
    resize: none;
    line-height: 140%;

    &:disabled[data-hasValue='true'] {
      color: $grayscale600;
    }
  }
  textarea::placeholder {
    color: $grayscale400;
    @extend .fontPrimaryNormal;
  }
}
