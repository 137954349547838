@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

// @font-face {
//   font-family: 'ProximaNova';
//   src: url('../assets/fonts/ProximaNova/ProximaNova-Black.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Black.woff2') format('woff2'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-BoldItalic.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Extrabold.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Italic.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Light.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-LightItalic.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Medium.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-MediumItalic.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Semibold.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-SemiboldItalic.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-Thin.woff') format('woff'),
//     url('../assets/fonts/ProximaNova/ProximaNova-ThinItalic.woff') format('woff');
// }
@font-face {
  font-family: 'Georgia';
  src: url('../assets/fonts/GeorgiaFont/Georgia.ttf') format('ttf');
}
.fontProximaNova {
  font-family: 'ProximaNova', sans-serif !important;
}
.fontGeorgia {
  font-family: 'Georgia', Fallback, sans-serif !important;
}

// Primary Colors
$primary: #188aa1;
$primary125: #096f84;
$primary75: rgba($primary, 0.75);
$primary50: #5EBBCE;
$primary20: #B6E3EB;
$primary10: rgba($primary, 0.1);
$primary5: #EFF9FB;

// Secondary Colors
$secondary: #ff8c07;
$secondary75: rgba($primary, 0.75);
$secondary50: rgba($primary, 0.5);
$secondary20: rgba($primary, 0.2);
$secondary10: rgba($primary, 0.1);
$secondary5: rgba($primary, 0.05);

// Status Colors

$successDark: #008869;
$successMiddle: #10db92;
$successLight: #eafff7;

$errorDark: #b7264d;
$errorMiddle: #D43F4C;
$errorLight: #ffefe9;
$error100: #861b38;

$alertDark: #b77f1e;
$alertMiddle: #fec43e;
$alertMiddleUp: #fec43e;
$alertLight: #fffbe6;
$alert10: #FFF3E6;
$alert50: #fff9da;
$alert100: #583800;
$alert125: #884901;

// Grayscale Colors
// used for the fonts

$grayscale900: #0d1c30;
$grayscale800: #1b2a3e;
$grayscale700: #314157;
$grayscale600: #43546d;
$grayscale500: #60768f;
$grayscale400: #8da0b9;
$grayscale300: #cbd4e1;
$grayscale200: #e2e8f0;
$grayscale100: #f1f5f9;
$grayscale50: #f8fafc;
$grayscale0: #ffffff;

$fontsPrimary: #314157;
// Gradients

$grayscale50Gradient: linear-gradient(90deg, rgba(248, 250, 252, 0) 0%, $grayscale50 100%);
$primary10Gradient: linear-gradient(270deg, $primary5 0%, $grayscale0 100%);

// Tags

$orangeLight: #fff9ea;
$yellowLight: #fdffe1;
$greenLight: #eefbf8;
$acquaLight: #e7feff;
$blueLight: #d6f2ff;
$marineLight: #e1e8ff;
$purpleLight: #f2e5ff;
$pinkLight: #ffebfe;
$orange: #d8a832;
$yellow: #cdd92d;
$green: #0dca9d;
$acqua: #0fc6d1;
$blue: #15a0e0;
$marine: #4062d1;
$purple: #9252d1;
$pink: #dc53d6;

//SAFARI ONLY MIXIN
@mixin safariOnly($property, $value) {
  // /* Safari only*/
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      #{$property}: $value;
    }
  }
}

//Common classes used on the page
.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.lytFlexColumn {
  display: flex;
  flex-direction: column;
}

.lytFlexRow {
  display: flex;
  flex-direction: row;
}

.lytFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lytFlexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// HTML tags defaults
.linkDefaultStying {
  text-decoration: none;
  color: $grayscale700;
  font-weight: normal;
  font-size: 16px;
}
.labelDefaultStyling {
  @extend .fontPrimaryNormal;
  font-weight: 400;
  color: $fontsPrimary !important;
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 14px !important;
}
.passwordInputDefault {
  color: $secondary;
  font-size: 14px !important;
}
.passwordDotsStylingDefault {
  //dots size when pass input===password
  input[type='password']:not(:placeholder-shown) {
    color: $grayscale800;
    font-weight: bold;
    // font-size: 34px !important;
  }
  //pass input bgr color when is not empty
  input:not(:placeholder-shown) {
    // background: $secondary10;
    color: $grayscale400;
  }
}

.passwordErrorDotsStyling {
  input[type='password']:not(:placeholder-shown) {
    color: $errorDark !important;
  }
  input:not(:placeholder-shown) {
    background: transparent;
  }
}

//Fonts styling

.fontPrimaryNormal {
  @extend .fontProximaNova;
}

.fontPrimaryBold {
  @extend .fontProximaNova;
  font-weight: 700;
}

.fontPrimaryNormalText {
  @extend .fontProximaNova;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.24px;
}

.fontPrimaryBoldText {
  @extend .fontProximaNova;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.24px;
}
.fontSecondaryBoldText {
  @extend .fontGeorgia;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.24px;
}

// Border styling
.borderDefault {
  border: 1px solid $grayscale200;
  border-radius: 5px;
}
// Label styling
.labelDefault {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

//input field styling
.inputDefault {
  height: 50px;
  font-size: 16px;
  @extend .fontPrimaryNormal;
}

// Typography
.bodyTextLarge {
  @extend .fontPrimaryNormalText;
  color: $grayscale900;
  font-size: 16px;
  line-height: auto;
}
.bodyTextMedium {
  @extend .fontPrimaryNormalText;
  color: $grayscale900;
  font-size: 14px;
  line-height: auto;
}
.bodyTextSmall {
  @extend .fontPrimaryNormalText;
  color: $grayscale900;
  font-size: 12px;
  line-height: auto;
}

.gradientWrapper {
  position: relative;
  padding: 20px 24px;
  border-radius: 16px;
  background: radial-gradient(
    86.32% 224.44% at 4.7% 7.78%,
    rgba(255, 175, 187, 0.05) 0%,
    rgba(255, 175, 187, 0.05) 20%,
    rgba(58, 160, 182, 0.05) 40%,
    rgba(87, 202, 225, 0.05) 60%,
    rgba(24, 138, 161, 0.05) 70.31%,
    rgba(115, 102, 217, 0.05) 81.77%,
    rgba(225, 194, 255, 0.05) 100%
  );

  &::after {
    position: absolute;
    border-radius: 15px;
    z-index: -1;
    content: '';
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: white;
  }

  &::before {
    position: absolute;
    border-radius: 15px;
    z-index: -1;
    content: '';
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: radial-gradient(
      rgba(255, 175, 187, 1),
      rgba(255, 175, 187, 0.48),
      rgba(58, 160, 182, 0.31),
      rgba(87, 202, 225, 1),
      rgba(24, 138, 161, 0.24),
      rgba(115, 102, 216, 0.31),
      rgba(225, 194, 255, 1)
    );
  }

  textarea {
    width: 100%;
    background: transparent;
    border: none;
    resize: none;
    outline: none;
    color: $grayscale700;
    font-size: 16px;
    font-weight: 500;

    &::placeholder {
      color: $grayscale400;
      font-weight: 500;
    }
    &:disabled {
      color: $grayscale400;
    }
  }
}

.pinToTop {
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 999;
}
