@import 'styles/commonStyling';

.wrapper {
    position: relative;
    height: 100%;
    transition: background-color 0.3s ease-in;
    &::before {
        content: '';
        position: absolute;
        left: -18px;
        width: 4px;
        height: 100%;
        transition: height 0.3s ease-in-out;
    }

    &[data-show-color='false'] {
        &::before {
            height: 0px !important;
        }
    }

    &[data-color='#03A59D'] {
        &::before {
            background-color: #03A59D;
        }
    }
    &[data-color='#15A0E0'] {
        &::before {
            background-color: #15A0E0;
        }
    }
    &[data-color='#4062D1'] {
        &::before {
            background-color: #4062D1;
        }
    }
    &[data-color='#9252D1'] {
        &::before {
            background-color: #9252D1;
        }
    }
    &[data-color='#C959C5'] {
        &::before {
            background-color: #C959C5;
        }
    }
    &[data-color='#B1A110'] {
        &::before {
            background-color: #B1A110;
        }
    }
}

.titleInputWrapper {
    margin-top: 3px;
}