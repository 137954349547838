@import 'src/styles/commonStyling';

.subHeaderContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 28.5px;
  padding-top: 17.5px;
  position: relative;
  &:after {
    background: linear-gradient(270deg, $grayscale100 0%, #ffffff 100%);
    position: absolute;
    left: -1000vw;
    bottom: 0;
    top: 0;
    right: -1000vw;
    width: auto;
    content: '';
    z-index: -1;
  }
  .title {
    text-transform: capitalize;
    color: $grayscale700;
  }
}
.subHeaderContainerTitle {
  padding-top: 28.5px;
}
