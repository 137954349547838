@import 'src/styles/commonStyling';

.defaultLink {
  &:hover {
    transform: scale(0.99);
  }
}

.linkDisabled {
  opacity: 0.3;
}
