@import 'styles/commonStyling';

.todoInput {
    &::placeholder {
        color: $grayscale400;
    }
    min-width: 0px;
    border: none;
    @extend .fontPrimaryNormal;
    font-weight: 500;
    color: $grayscale600;
    font-size: 16px;
    outline: none;
}

.exitSearchIcon {
    font-size: 20px;
    user-select: none;
    display: flex;
    color: $grayscale500;
    padding-inline: 0px !important;
    padding-block: 0px !important;
}

.searchWrapper {
    display: flex;
    gap: 4px;
    width: auto;
    align-items: center;
    justify-content: space-between;
    padding-inline: 8px;
    padding-block: 4px;

    &[data-search-opened="true"] {
        width: 100%;
        border: 1px solid $grayscale300;
        border-radius: 100px;
    }

  &[data-has-value="true"] {
    border-color: $primary;
  }
}

.actionButton {
    user-select: none;
    display: flex;
    color: $primary;
    padding-inline: 0px !important;

    & > span {
        align-self: center;
    }

    &:disabled {
        color: $grayscale400;
        cursor: initial;
    }
}