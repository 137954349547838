@import 'styles/commonStyling.scss';

.header {
    background-color: $grayscale50;
    padding: 12px 20px;
}

.impactId {
    font-size: 12px;
    color: $grayscale700;
}