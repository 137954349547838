@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.confirmBoxContainer {
  @extend .lytFlexCenter;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  > h3 {
    margin-bottom: 34px;
    font-size: 24px;
    line-height: 29.23px;
    margin-right: 20px;
    letter-spacing: 0px;
    text-align: left;
   
  }

  .text {
    font-size: 16px;
    color: $grayscale700;
    width: 100%;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 24px;
    @extend .fontPrimaryNormalText;
  }

  .buttonsSubmitReset {
    width: 100%;
    display: flex;
    font-size: 16px;
    color: $grayscale700;
    @extend .fontPrimaryBoldText;
    margin-top: 24px;
    flex-direction: row;
    gap: 16px;
  
    .whiteButton {
      background: $grayscale700;
      color: $grayscale0;
      &:disabled {
        background: $grayscale300;
        border: none;
      }
    }
  }
}
