@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.buttonStyle {
  font-size: 14px;
  width: 100%;
  height: 48px;
  border-radius: 80px;
  // padding: 12px 24px;
  @extend .fontPrimaryBoldText;

  &:disabled {
    background: $primary20;
    border: 1px solid $primary20;
    cursor: initial;
  }
  &:enabled:hover {
    transform: scale(0.99);
  }
}
.buttonStylePrimary {
  background: $primary;
  color: $grayscale0;
  border: 1px solid $primary;
  @extend .fontPrimaryNormalText;
  font-weight: 500;

  &:disabled {
    background: $grayscale300;
    color: $grayscale0;
    border: none;
  }

  span {
    span {
      color: #fff;
    }
  }
}
.buttonStyleSecondary {
  background: $primary5;
  color: $primary125;
  font-size: 14px;
  width: 100%;
  border: 1px solid $primary;
  border-radius: 80px;
  @extend .fontPrimaryNormalText;
  font-weight: 500;

  &:disabled {
    background: $grayscale100;
    color: $grayscale400;
    border: none;
  }
  &:hover {
    transform: scale(0.99);
  }
}
.buttonStyleTransparent {
  background: transparent;
  border: 1px solid $grayscale200;
  @extend .fontPrimaryNormalText;
  font-weight: 500;
  span {
    color: $primary;
  }

  &:enabled:hover {
    background-color: $grayscale50;
    border-color: $grayscale100;
  }

  &:disabled {
    cursor: initial;
    span {
      color: $grayscale400;
    }
    background: transparent;
    border: 1px solid $grayscale100;
  }

  &:enabled:hover {
    background-color: $grayscale100;
  }
}
.buttonStyleCircle {
  width: 50px !important;
  border-radius: 50%;
  .btnInsideSpan {
    span {
      margin-right: unset !important;
    }
  }
}
.buttonStyleSmall {
  height: 40px !important;
}
.buttonStyleExtraSmall {
  height: 28px !important;
}

.buttonStyleBorderless {
  background: transparent;
  border: none;
  @extend .fontPrimaryNormalText;
  font-weight: 500;
  span {
    color: $primary;
  }

  &:hover {
    background-color: $grayscale50;
    border-color: $grayscale100;
  }

  &:disabled {
    span {
      color: $grayscale400;
    }
    background: transparent;
    border: 1px solid $grayscale100;
  }
}

.btnInsideSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  span {
    margin-right: 8px;
    font-size: 32px;
  }
}
.buttonWithIcon {
  span {
    margin: 0px;
  }
}
