@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.AmountProgress {
  width: 100%;
  max-width: 280px;
  margin-right: 37px;
  @include media('<large') {
    max-width: 100%;
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 8px;
    text-transform: capitalize;
    @extend .lytFlexBetween;
    @extend .fontPrimaryBold;
    span {
      font-weight: 600;
      line-height: 24px;
      text-transform: lowercase;
    }
    @include media('<medium') {
      flex-direction: column;
      align-items: start;
    }
    @include media('<large') {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.24px;
      margin-bottom: 4px;
      span {
        font-weight: 400;
      }
    }
  }
  .progress {
    position: relative;
  }
  .amount {
    z-index: 1;
    left: 5px;
    position: absolute;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: $grayscale0;
    @extend .fontPrimaryBold;
    @include media('<large') {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.24px;
    }
  }
  .linearProgress {
    height: 30px;
    background-color: $grayscale100;
    border-radius: 4px;
    @include media('<large') {
      height: 20px;
    }
    span {
      background: red;
      border-radius: 4px;
    }
  }
}
