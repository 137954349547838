@import 'src/styles/commonStyling';

.breadcrumbs {
  text-transform: capitalize;
  margin-bottom: 14px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  a {
    color: $grayscale500;
    text-decoration: none;
  }
  .last {
    color: $primary;
    font-weight: 500;
  }
}

.withBackground {
  background-color: $grayscale50;
  padding: 8px 24px;
}

.separator {
  font-size: 12px !important;
  color: $grayscale300;
}
