@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.datePicker {
  display: flex;
  align-items: center;
  background: $grayscale0;
  border: 1px solid $grayscale300;
  border-radius: 4px;
  padding: 0 12px;
  height: 50px;
  width: calc(100% - 24px);
  & > span {
    color: $grayscale500;
    font-size: 20px !important;
    margin-right: 14px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  input {
    background-color: transparent;
    width: 100%;
    font-size: 16px;
    @extend .fontPrimaryNormal;
    border: none;
    outline: none;
    &::placeholder {
      color: $grayscale400;
      opacity: 1;
    }
  }
}

.disabled {
  background: $grayscale100;
}

.timePickerMUi {
  fieldset {
    border: none;
  }
}

.timeOptions {
  max-height: 330px;
  background: $grayscale0;
  z-index: 111111;
  overflow-y: auto;
  box-shadow: 0 2px 16px 0 rgba(80, 117, 177, 0.1);
  border-radius: 8px;
  li {
    @extend .fontPrimaryNormal;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    padding: 8px 12px;
    &:hover {
      background: $grayscale100;
    }
  }
  .highlighted {
    background: #eff9fb;
    color: $primary125;
  }
}
