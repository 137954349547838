@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.logoOrange {
  width: 256px;
  height: 54.857px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/png/logoOrange.png');
}
.logoWhite {
  height: 110px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/png/logoWhite.png');
}
.logoSmall {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/logoSmall.svg');
  @include media('<large') {
    padding: 0;
    background-image: url('../../assets/svg/logoSmallOrange.svg');
  }
}
