/* stylelint-disable css-modules/no-global-scoped-selector */
@import './responsive';
@import './commonStyling';
@import './toastifyOverride';
@import './reset';

.globalErrorBorder {
  border: 1px solid $errorMiddle !important;
  color: $errorMiddle;
  * {
    color: $errorMiddle !important;
  }
}
.globalValidBorder {
  border: 1px solid $successMiddle !important;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  color: $fontsPrimary;
  @extend .fontPrimaryNormal;
}

button,
a {
  cursor: pointer;
  @extend .fontPrimaryNormal;
  border: none;
  background: transparent;
  text-decoration: none;
  color: initial;
  &:active,
  &:focus {
  }
}

* {
  --scrollbar-color-thumb: #c7c7c7;
  --scrollbar-color-track: #ffffff;
  --scrollbar-width-legacy: 6px;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}