@import 'src/styles/commonStyling.scss';

.text {
  color: $grayscale700;
  font-size: 16px;
  font-weight: 500;
  white-space: pre-line;
}

.label {
  @extend .text;
  font-weight: 700;
}

.addendumWrapper {
  border-top: 1px solid $grayscale100;
  padding-top: 16px;
}

.autosave {
  display: flex;
  align-items: center;
  color: $grayscale500;
  font-size: 12px;
  font-weight: 500;

  & > span {
    font-size: 20px;
    margin-right: 8px;
  }
}

.icon {
  color: $primary125;
  cursor: pointer;

  &[aria-disabled='true'] {
    color: $grayscale400;
    cursor: not-allowed;
  }
}
