@import '../../../styles/responsive';
@import '../../../styles/commonStyling';

.pageContainer {
  @extend .pageContainer;
  .insideContainer {
    @extend .pageContainer;
    max-width: 100%;
    .sidebarAndMainContent {
      width: 100%;
      margin: 8px;
      @extend .lytFlexRow;
      .sidebar {
        margin: 8px;
        width: calc(20% - 16px);
      }
      .pageContentContainer {
        width: calc(80% - 16px);
        @extend .lytFlexColumn;
        border-radius: 16px;
        margin: 8px;
      }
      .pageContentContainerFullWidth {
        width: 100%;
        @extend .lytFlexColumn;
      }
    }
  }
}

.linkItem {
  li {
    @extend .bodyTextLarge;
    color: $grayscale500;
    line-height: 22.4px
  }
}

.linkItemSelected {
  li {
    @extend .linkItem;
    color: $grayscale800;
    font-weight: 700;
  }
}

.selectedProgram {
  @extend .bodyTextLarge;
  color: $grayscale800;
  font-weight: 600;
}

.selectedProgram::before {
  position: absolute;
  left: -24px;
  content: '';
  width: 14px;
  height: 9px;
  border-left: 2px solid $grayscale200;
  border-bottom: 2px solid $grayscale200;
  border-bottom-left-radius: 4px;
}
