@import '../../styles/commonStyling';

.popper {
  z-index: 1111;
}

.content {
  background: white;
  padding: 12px;
  box-shadow: 0 2px 16px rgba(80, 117, 177, 0.1);
  border-radius: 8px;
  margin-right: 90px;
  min-width: 245px;
  .header {
    @extend .lytFlexBetween;
    .closeIcon {
      width: 35px;
      height: 35px;
      cursor: pointer;
      color: $secondary;
    }
  }
  .logout {
    @extend .fontPrimaryNormalText;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .logoutIcon {
    margin-right: 6px;
    color: $secondary;
  }
}
