@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.helpSectionContainer {
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  line-height: 140%
}
.linkHelp {
  color: $primary;
  font-weight: 600;
}
