@import 'styles/commonStyling.scss';

.autosave {
    display: flex;
    align-items: center;
    color: $grayscale500;
    font-size: 12px;
    font-weight: 500;

    & > span {
        font-size: 20px;
        margin-right: 8px;
    }
}