@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.goalModal {
  display: flex;
  width: 100%;
  .imgWrap {
    border-right: 2px solid $grayscale100;
    width: 203px;
    background: #f3fdff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .text {
      font-weight: 700;
      font-size: 39px;
      margin-top: 82px;
    }
    img {
      width: 100%;
      height: 160px;
      margin-top: 90px;
    }
  }
  .content {
    padding: 24px;
    width: calc(100% - 205px);
    @extend .lytFlexColumn;
    justify-content: space-between;
    .title {
      margin-bottom: 28px;
      margin-right: 28px;
      .titleText {
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: $grayscale600;
        p {
          margin-top: 20px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: -6px;
        }
      }
      .closeIcon {
        display: flex;
        margin-left: auto;
        cursor: pointer;
        color: $grayscale700;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 11;
      }
    }
    .subTitle {
      @extend .lytFlexBetween;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      color: $grayscale600;
      .count {
        color: $grayscale400;
      }
      margin-bottom: 8px;
    }
    .inputItem {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: $grayscale600;
        margin-bottom: 8px;
        display: block;
      }
      .helpText {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $grayscale600;
        margin-top: 8px;
        a {
          font-weight: 700;
          text-decoration: underline;
          color: #188aa1;
        }
      }
      .fieldError {
        @extend .fontPrimaryNormal;
        margin-top: 3px;
        color: $errorMiddle;
        font-size: 12px;
      }
    }
    .finishText {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.4px;
    }
  }
  .history {
    margin: 24px 0;
    .historyTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $grayscale600;
      padding-bottom: 8px;
      border-bottom: 2px solid $grayscale200;
    }
    table {
      tr {
        border-bottom: 1px solid $grayscale100;
        &:last-child {
          border-bottom: 0;
        }
        td {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: $grayscale600;
          padding: 10px 0;
          border: 0;
          .counter {
            font-weight: 700;
            display: inline-block;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .date {
            font-weight: 400;
            font-size: 12px;
          }
          .subName {
            margin-left: 6px;
            padding: 2px 8px;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: $grayscale500;
            border-radius: 100px;
            background: $grayscale100;
            display: flex;
            align-items: center;
            & > div {
              margin-right: 4px;
              background: $grayscale100;
              color: $grayscale400;
              width: 16px;
              height: 16px;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .buttonsWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -15px;
    button {
      margin: 0 15px;
      width: calc(50% - 30px);
      span {
        font-weight: 700;
        line-height: 22px;
        font-size: 18px;
      }
    }
  }
}
.goalModalInside {
  padding: 0;
  overflow: hidden;
  top: auto;
  bottom: auto;
  width: 635px;
  & > div {
    width: 635px;
    & > div {
      right: 20px;
      top: 20px;
      span {
        font-size: 32px;
      }
    }
  }
}
