@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.switchButtons {
  @extend .lytFlexRow;
  width: 100%;

  button {
    padding: 0 16px;
    height: 44px;
    border: 1px solid $grayscale300;
    margin-right: 4px;
    line-height: 140%;
    font-weight: 500;
    font-size: 14px;
    color: $grayscale700;
    cursor: pointer;
    border-radius: 4px;
    outline: none !important;
    &:disabled {
      color: $grayscale500;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .fullWidth {
    width: 100%;
    padding: 0;
    margin: 0;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
  .selected {
    border: 1px solid $primary50;
    color: $primary125;
    background: $primary5;
    font-weight: 600;
    &:disabled {
      background: $grayscale100;
      border: 1px solid $grayscale300;
      color: $grayscale600;
    }
  }
}
