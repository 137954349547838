@import 'styles/commonStyling';

.colorPicker {
    padding-top: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 40px;
    border: 1px solid transparent;

    &[data-selected='true'] {
        border: 1px solid  $grayscale300;
    }
}

.color {
    border-radius: 40px;
    width: 15px;
    height: 15px;
} 