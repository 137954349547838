@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.datePicker {
  display: flex;
  align-items: center;
  background: $grayscale0;
  border: 1px solid $grayscale300;
  border-radius: 4px;
  padding: 0 12px;
  height: 50px;
  & > span {
    color: $grayscale500;
    font-size: 20px !important;
    margin-right: 14px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  input {
    background-color: transparent;
    width: 100%;
    font-size: 16px;
    @extend .fontPrimaryNormal;
    border: none;
    outline: none;
    &::placeholder {
      color: $grayscale400;
    }
  }
}

.withGradient {
  position: relative;
  border-radius: 16px;
  background: radial-gradient(
    86.32% 224.44% at 4.7% 7.78%,
    rgba(255, 175, 187, 0.05) 0%,
    rgba(255, 175, 187, 0.05) 20%,
    rgba(58, 160, 182, 0.05) 40%,
    rgba(87, 202, 225, 0.05) 60%,
    rgba(24, 138, 161, 0.05) 70.31%,
    rgba(115, 102, 217, 0.05) 81.77%,
    rgba(225, 194, 255, 0.05) 100%
  );
  border: none;

  &::after {
    position: absolute;
    border-radius: 15px;
    z-index: -1;
    content: '';
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: white;
  }

  &::before {
    position: absolute;
    border-radius: 15px;
    z-index: -1;
    content: '';
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: radial-gradient(
      rgba(255, 175, 187, 1),
      rgba(255, 175, 187, 0.48),
      rgba(58, 160, 182, 0.31),
      rgba(87, 202, 225, 1),
      rgba(24, 138, 161, 0.24),
      rgba(115, 102, 216, 0.31),
      rgba(225, 194, 255, 1)
    );
  }
}
.disabled {
  background: $grayscale100;
  pointer-events: none;
  input {
    color: $grayscale400;
  }
}
