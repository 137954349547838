@import 'src/styles/commonStyling.scss';

.container {
    z-index: 9999;
}

.popper {
    max-width: 252px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 4px 4px 25px 0px #5075B126, 1px 1px 4px 0px #5075B133;
    padding: 12px;
}