@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.enrollmentGoalsProgress {
  border: 1px solid $grayscale100;
  .titleWrap {
    background: $grayscale50;
    padding: 8px 12px;
    @extend .lytFlexBetween;
    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: $grayscale500;
    }
    .buttonsWrap {
      @extend .lytFlexCenter;
      button {
        width: auto;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
        }
        padding: 0 16px;
      }
      .buttonReset {
        border: none;
        padding: 0;
        margin-right: 16px;
      }
    }
  }
  .trackerWrap {
    max-height: 200px;
    overflow-y: auto;
    padding-top: 8px;
    .item {
      padding: 6px 8px;
      @extend .lytFlexBetween;
      .nameWrap {
        @extend .lytFlexCenter;
        .avatar {
          background: $grayscale100;
          width: 24px;
          height: 24px;
          margin-right: 12px;
          span {
            font-size: 12px;
            color: $grayscale400;
          }
        }
        .name {
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          color: $grayscale600;
        }
        .nameDisabled {
          color: $grayscale400;
        }
      }
      .contentWrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .counter {
        background: $grayscale100;
        @extend .lytFlexCenter;
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: $grayscale500;
        padding: 2px 8px;
        height: 20px;
        margin-right: 20px;
        p {
          font-weight: 700;
        }
      }
      .counterError {
        color: $errorDark;
        background: $errorLight;
        .icon {
          font-size: 16px;
          color: $errorDark;
        }
      }
      .addButton {
        border: none;
        width: auto;
        margin-left: 60px;
        span {
          font-size: 14px;
          color: $primary125;
        }
        &:disabled {
          color: $grayscale400;
          cursor: pointer;
          pointer-events: none;
          &:hover {
            background: transparent;
          }
          span {
            color: $grayscale400;
          }
        }
      }
      .dragIndicator {
        font-size: 14px;
        color: $grayscale300;
        margin-right: 13px;
      }
    }
    .selected {
      box-shadow: 1px 1px 2px rgba(80, 117, 177, 0.1), 4px 4px 40px rgba(80, 117, 177, 0.1);
      background: #fff;
    }
  }
  .trackerWrapOpen {
    .item:last-child {
      margin-bottom: 40px;
    }
  }
}

.enrollmentGoalTrackerWrap {
  width: 488px;
  & > div {
    margin: 0 !important;
    height: 18px !important;
  }
}

.buttonOpen {
  background: $grayscale100;
  border-radius: 100px;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px auto 8px auto;
  cursor: pointer;
  user-select: none;
  span {
    font-size: 12px;
    color: $grayscale500;
  }
}
.buttonOpened {
  transform: rotate(-180deg);
}
.noData {
  padding: 6px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: $grayscale600;
}
.loading {
  @extend .lytFlexCenter;
}
.placeholderNone {
  display: none;
}