@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.inputContainerIcon {
  @extend .lytFlexRow;
  @extend .lytFlexCenter;
  align-items: center;
  background: white;
  box-shadow: 0 1px 1px rgba(27, 42, 62, 0.05), 0 3px 8px rgba(27, 42, 62, 0.08);
  width: 65px;
  height: 28px;
  position: relative;

  border-radius: 109px;
}
.disableHints {
  width: 50px;
  height: 21px;
  div {
    font-size: 12px;
    span {
      font-size: 15px;
    }
  }
}