@import 'styles/commonStyling.scss';

.emptyMessage {
    @extend .fontPrimaryNormal;
    color: $grayscale700;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    padding: 20px;

    & > span {
        font-weight: 600;
    }
}
