@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.inputContainer {
  padding-left: 17px;
  padding-right: 17px;
  @extend .borderDefault;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;

  input {
    background-color: transparent;
    width: 100%;
    flex: 1;
    @extend .inputDefault;
    border: none;
    outline: none;
  }

  input[type="number" i] {
    padding: 0px;
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grayscale400;
    // opacity: 1; /* Firefox */
  }
  input:disabled {
    color: $grayscale400;
  }

  input:disabled[data-hasValue='true'] {
    color: $grayscale600;
  }
}
.passwordInputStyling {
  @extend .passwordInputDefault;
}
.passwordDotsStyling {
  @extend .passwordDotsStylingDefault;
}
.passwordErrorDotsStyling {
  @extend .passwordDotsStylingDefault;
}

.disabled {
  background: $grayscale100;
}

.rounded {
  border-radius: 80px;
}

.visibilityIconContainer {
  display: flex;
  justify-content: flex-end;
  // position: relative;
  align-items: center;
  top: 15px;
  cursor: pointer;
}

.tags {
  max-width: 40%;
  overflow-x: auto;
  display: flex;

  & > * + * {
    margin-left: 4px !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
