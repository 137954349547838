@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.loginEmailContainer {
  width: 100%;
  

  display: flex;
  flex:1;
  flex-direction: column;
  height: 100VH;

  justify-content: center;
  align-items: center;
  & > form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 318px;

    .inputGroup {
      .inputGroupField {
        border-radius: 4px;
        border-color: $grayscale300;
      }
      input {
        height: 46px;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        color: $grayscale800;
        padding: 0;
        border: none;
      }
      button {
        span {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
  label {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
}
.logoContainer {
  @extend .lytFlexCenter;
  margin-bottom: 40px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  flex: 100%;
  margin-bottom: 24px;
}
.haveAccount {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @extend .fontPrimaryBoldText;

  a {
    @extend .linkDefaultStying;
  }
}
.createAccount {
  a {
    color: $primary;
  }
}
.fieldError {
  margin-top: 3px;
  color: $errorMiddle;
  // color: #D43F4C;
}

.helpSection {
  margin-top: 16px;
}
