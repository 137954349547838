@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.defaultStyle {
  @extend .fontPrimaryNormal;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  border-radius: 100px;
  padding: 8px 12px;
  font-size: 12px;
  gap: 8px;
  color: $grayscale500;
  background-color: $grayscale100;
  white-space: nowrap;
}

.badgeSuccess {
  background-color: $successLight;
  color: $successDark;
}

.badgeWarning {
  background-color: $alert10;
  color: $alert125;
  span {
    color: $alertMiddle;
  }
}

.icon {
  color: $grayscale500;
  font-size: 20px !important;
  font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48 !important;
}

.small {
  padding: 2px 8px;
  font-size: 12px;
}
