@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.inputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 750px;
  overflow: auto;
}

.notesHeader {
  @extend .lytFlexCenter;
  justify-content: flex-start;
  height: 48px;
  background: $grayscale50;
  padding: 4px 4px 4px 16px;
  margin-bottom: 24px;
  margin-top: 32px;
  button {
    margin-right: 0;
    margin-left: auto;
    width: 50px;
  }
}

.notesBody {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  // background: red;

  .candidateInputGroup {
    width: 100%;

    .blueAccordionBox {
      cursor: pointer;

      @extend .lytFlexCenter;
      @extend .gradientWrapper;
      padding: 10px;
      height: 5px;
      span {
        color: $primary;
      }
    }
  }
}

.note {
  width: 100%;
  height: 60px;
  .noteRow {
    display: flex;
    justify-content: space-between;
  }
}

.notesPhoto {
  padding: 10px;
  height: 229px;
  display: flex;
  flex-direction: column;
}

.uploadImg {
  button {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
  }
  button > span > span {
    font-size: 24px;
  }
}

.clientImageBgr {
  height: 229px;
  object-fit: contain;
  object-position: 50% 50%;
  animation: fadeIn 1.4s;
  max-width: 406px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spinner {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
