
@import 'styles/commonStyling';

.checkbox {
    display: block;
    padding-right: 10px;
    input[type='checkbox'] {
        width: 18px !important;
        height: 18px !important;
        border-radius: 2px !important;
    }

    input[type='checkbox']::before {
        transition: all 0.3s ease-in-out;
        box-shadow: inset 0 0 0 2px $grayscale400 !important;
    }

    input[type='checkbox']:disabled::before {
        box-shadow: inset 0 0 0 2px $grayscale200 !important;
    }

    input[type='checkbox']:checked::before {
        box-shadow: none !important;
    }
}