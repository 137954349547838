@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.progressValue {
  animation: load 3s normal forwards;

  height: 4px;
  width: 0;
  position: relative;
}
