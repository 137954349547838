@import 'styles/commonStyling.scss';

.addendumWrapper {
    padding-top: 20px;
}

.autosave {
    display: flex;
    align-items: center;
    color: $grayscale500;
    font-size: 12px;
    font-weight: 500;

   & > span {
    font-size: 20px;
    margin-right: 8px;
   }
}

.icon {
    color: $primary125;
    cursor: pointer;

    &[aria-disabled="true"]{
        color: $grayscale400;
        cursor: not-allowed;
    }
}