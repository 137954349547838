@import '../../../styles/commonStyling';
@import '../../../styles/responsive';

.profile {
  width: 270px;
  .bottomWrap {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $grayscale200;
    .text {
      margin-bottom: 9px;
      display: flex;
      cursor: pointer;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $fontsPrimary;
      }
      span {
        color: $grayscale500;
        font-size: 20px;
      }
    }
  }
  .avatar {
    background: $grayscale100;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    span {
      color: $grayscale400;
    }
  }
  .profileWrap {
    display: flex;
    align-items: center;
    width: 100%;
    label {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #096F84;
      cursor: pointer;
      margin-top: 4px;
    }
    input {
      display: none;
    }
  }
  .titleWrap {
    display: flex;
    flex-direction: column;
  }
  .name {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 6px;
    text-transform: capitalize;
  }
  .role {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #188AA1;
    border: 1px solid #188AA1;
    border-radius: 100px;
    padding: 3px 6px;
    width: fit-content;
  }
  .pointer {
    cursor: pointer;
  }
  .textWrap {
    margin-top: 20px;
  }
  .text {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    p {
      max-width: 60%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $grayscale500;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: $grayscale300;
      margin-right: 10px;
    }
  }
  form {
    margin-top: 16px;
  }
  .formInput {
    margin-bottom: 8px;
    label {
      margin-bottom: 2px;
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
    }
    input {
      padding: 0;
      outline: none;
      height: auto;
    }
  }
  .inputForm {
    padding: 12px 16px 12px 12px !important;
  }
  .fieldError {
    margin-top: 3px;
    color: $errorDark;
  }
  .buttonsWrap {
    .submit {

      width: 100%;
      background: #188AA1;
      padding: 12px 16px;
      border-radius: 800px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $grayscale0;
      border: none;
      &:disabled {
        background: $grayscale300;
        border: none;
      }
    }
    .reset {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #188AA1;
      height: auto;
      margin-top: 12px;
    }
  }
  .profileEdit {
    align-items: start;
  }
}
