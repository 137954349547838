@import 'src/styles/commonStyling.scss';

.notePopup {
  margin-bottom: 16px;
}

.avatar {
  width: 16px;
  height: 16px;
}

.authorName {
  color: $grayscale500;
  font-size: 12px;
  font-weight: 400;
}

.date {
  color: $grayscale500;
  font-size: 12px;
  font-weight: 500;
}
