@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.inviteExpiredContainer {
  @extend .lytFlexCenter;
  height: 100vh;
  .inviteExpiredInsideContainer {
    width: 475px;
    height: 152px;
    @include media('<large') {
      width: 327px;
    }

    @extend .lytFlexColumn;
    align-items: center;
    .logoContainer {
      width: 256px;
    }
    .textContainer {
      margin-top: 12px;
      @extend .lytFlexColumn;
      @extend .lytFlexCenter;
      h2 {
        color: $primary;
      }
      p {
        text-align: center;
        margin-top: 4px;
        color: $grayscale700;
        font-size: 14px;
      }
    }
  }
}
