@import 'src/styles/commonStyling';

.expander {
  margin-bottom: 36px !important;
  min-height: auto !important;
  &:before {
    display: none;
  }
  .titleWrap {
    @extend .lytFlexBetween;
    width: 100%;
    margin-right: 8px;
  }
  .buttonsWraps {
    @extend .lytFlexBetween;
  }
  .title {
    padding: 16px 24px;
    background: $grayscale50;
    min-height: auto !important;
    color: $fontsPrimary;
    & > div {
      margin: 0;
    }
    .icon {
      width: 34px !important;
      height: 34px !important;
      span {
        margin: auto;
        font-size: 24px;
        color: $fontsPrimary;
      }
    }
  }
  .content {
    margin-top: 28px !important;
  }
}

.expanderBlue {
  margin-bottom: 0px !important;
  .title {
    padding: 12px 16px;
    h2 {
      color: $grayscale600;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
    }
    .icon {
      border: none;
      width: 24px !important;
      height: 24px !important;
      span {
        margin: auto;
        font-size: 24px;
        color: $primary125;
      }
    }
  }
  .content {
    margin-top: 16px !important;
    padding: 0;
  }
}