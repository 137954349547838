@import 'src/styles/commonStyling';

.sectionTitle {
    font-size: 16px;
    color: $grayscale500;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
}

.blackTitle {
    font-weight: 700;
    color: $grayscale800;
}