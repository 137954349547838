@import '../../styles/commonStyling';

.sidebar {
  background: $grayscale600;
  & > div:nth-child(3) {
    background: $grayscale0;
    box-shadow: 0 2px 14px rgba(2, 48, 71, 0.14);
    border-radius: 4px 0 0 4px;
    padding: 30px;
    height: calc(100vh - 64px);
    width: 600px;
    h2 {
      text-transform: capitalize;
    }
    .titleWrap {
      @extend .lytFlexBetween;
      margin-bottom: 40px;
    }
    .closeIcon {
      cursor: pointer;
      color: $secondary;
    }
    .content {
      overflow-y: auto;
    }
  }
}
