@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.helpButton {
  margin-right: 20px;
  padding: 0;
  span {
    color: $grayscale400;
    span {
      font-size: 32px;
    }
  }
}
.modal {
  top: auto;
  & > div {
    width: auto;
  }
  h3 {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 33px;
  }
  .content {
    p {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      display: flex;
      align-items: center;
      b {
        font-weight: 700;
      }
      a {
        color: $primary;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .copyButton {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-left: 60px;
      span {
        font-size: 32px;
        color: $grayscale0;
      }
    }
  }
}