@import 'styles/commonStyling.scss';

.todoInput {
    background-color: transparent;
    &::placeholder {
        color: $grayscale400;
    }
    width: 100%;
    border: none;
    @extend .fontPrimaryNormal;
    font-weight: 500;
    color: $grayscale600;
    font-size: 16px;
    outline: none;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-all;
    padding: 0px;
}

.growWrap {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: grid;
}
.growWrap::after {
    font-size: 16px;
    @extend .fontPrimaryNormal;
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-all;
    visibility: hidden;
}
.growWrap > textarea {
    resize: none;
    overflow: hidden;
}
.growWrap > textarea,
.growWrap::after {
    font: inherit;

    grid-area: 1 / 1 / 2 / 2;
}