@import 'styles/commonStyling';

.icon {
    font-size: 20px;
    transition: color 0.3s ease-in-out;
    &:disabled {
        color: $grayscale300 !important;
        cursor: default;
    }
}
