@import '../../styles/commonStyling';

.wrapper {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 100;
    display: flex;
    align-items: flex-end;
}

.button {
    margin-right: 10px;
    margin-bottom: 12px;
    width: 64px;
    height: 64px;
    border-radius: 800px;
    background-color: $primary;
    & > span {
        font-size: 30px;
        color: white;
    }
}

.sidebar {
    height: 100%;
    background-color: white;
    padding-top: 20px;
    box-sizing: border-box;
    min-width: 350px;
    overflow: hidden;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 91;
}

.title {
    @extend .fontPrimaryNormal;
    color: $grayscale600;
    font-size: 20px;
    font-weight: 700;
    min-width: 0px;
}

.close {
    display: flex;
    color: $grayscale500;
    font-size: 30px;
    padding-block: 0px !important;
    padding-inline: 0px !important;
    & > span {
        align-self: center;
    }
}

.actionButton {
    user-select: none;
    display: flex;
    color: $primary;
    padding-inline: 0px !important;

    & > span {
        align-self: center;
    }
}

.todoInput {
    &::placeholder {
        color: $grayscale400;
    }
    min-width: 0px;
    border: none;
    @extend .fontPrimaryNormal;
    font-weight: 500;
    color: $grayscale600;
    font-size: 16px;
    outline: none;
    width: 100%;
}

.actionItem {
    color: $grayscale400;
    font-size: 20px;
}