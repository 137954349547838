@import 'src/styles/commonStyling';

.date {
    @extend .fontPrimaryNormal;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: $grayscale700;
}

.icon {
    color: $grayscale400;
    font-size: 24px;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;

    &[data-expanded="true"] {
        transform: rotate(180deg);
    }

    &[data-disabled="true"] {
        color: $grayscale300;
    }
}

.expandButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-inline: 20px;
    padding-block: 8px;

    &:disabled {
        cursor: initial;
    }
}