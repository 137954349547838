@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.ourWorkExpanderSection {
  width: 100%;
  max-width: 300px;
  margin-left: 16px;
  & > div {
    margin-bottom: 8px !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  .iconsWrap {
    @extend .lytFlexBetween;
  }
  .iconAdd {
    border: none;
    width: 24px !important;
    height: 24px !important;
    span {
      margin: auto;
      font-size: 24px;
      color: $primary125;
    }
  }
  .roadmapToggle {
    display: flex;
    padding: 2px;
    border-radius: 30px;
    background: $grayscale200;
    margin-right: 8px;
    .toggleItem {
      border-radius: 30px;
      padding: 0 8px;
      text-align: center;
      vertical-align: middle;
      span {
        font-size: 20px;
        color: $grayscale400;
      }
    }
    .activeToggle {
      background: $primary;
      span {
        color: $grayscale0;
      }
    }
  }
}