@import 'src/styles/commonStyling';

.title {
    @extend .fontPrimaryNormal;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    color: $grayscale700;
}

.wrapper {
    position: relative;
    height: 100%;
    background-color: white;
    transition: background-color 0.2s ease-in-out;
    
    &:hover {
        background-color: $grayscale50;
        cursor: pointer;
    }

    &[data-open='true'] {
        background-color: $grayscale50;
    }
}

.colorBar {
    width: 4px;

}


.todoInput {
    margin-top: 2px;
    font-size: 14px !important;
    &[data-overdue='true'] {
        color: $errorMiddle;
    }

    &[data-status='COMPLETED'] {
        color: $primary125;
        pointer-events: none;
    }

    &[data-status='ABANDONED'] {
        color: $grayscale400;
        pointer-events: none;
    }

    &[disabled] {
        pointer-events: none;
    }
}

.titleInputWrapper {
    margin-top: 2px;

    &::after {
        font-size: 14px;
        @extend .fontPrimaryNormal;
        content: attr(data-replicated-value) " ";
        white-space: pre-wrap;
        word-break: break-word;
        word-wrap: break-all;
        visibility: hidden;
    }
}

.info {
    @extend .fontPrimaryNormal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $grayscale700;
    max-width: 280px;

    .separator {
        color: $grayscale300;
    }

    &[data-overdue='true'] {
        color: $errorMiddle;
    }

    &[data-status='COMPLETED'] {
        color: $primary125;
    }

    &[data-status='ABANDONED'] {
        color: $grayscale400;
    }
}

.lockIcon {
    color: $grayscale400;   
    font-size: 20px;
    margin-right: 7px;
}