@import 'src/styles/commonStyling';

.codeInput {
    & input {
        @extend .fontPrimaryNormal; 
        width: 46px;
        height: 46px;
        border-radius: 4px;
        margin: 4px;
        text-align: center;
        outline: none;
        border: 1px solid #e2e8f0;
        overflow: hidden;
        color: $grayscale700;
        font-size: 18px;
        font-weight: 700;

        &::placeholder {
            overflow: hidden;
            color: $grayscale400;
            font-size: 18px;
            font-weight: 700;
        }

        &:focus::placeholder {
            color:transparent; 
        }

        &[data-valid="true"] {
            border: 1px solid #10db92;
        }
    }
}