@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.breadcrumbContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 67px;
  margin-right: auto;
  margin-left: 0px;
  padding-left: 0px;
  justify-content: flex-start;

  @extend .fontPrimaryBoldText;
  color: $secondary;
  svg {
    font-size: 14px;
  }
  @include media('<small') {
    margin-bottom: 15px;
  }
}
