@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.checkboxWrap {
  & > div {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
