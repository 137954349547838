.h0 {
  font-weight: 700;
  font-size: 42px;
  line-height: 58px;
}
.h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}
.h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}
.h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
.h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
